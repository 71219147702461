import React from 'react';
import { Result, Row } from 'antd';

const NotFoundPage: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </Row>
  )
};

export default NotFoundPage;